<template>
  <div>
    <!-- ---------- -->

    <div class="footer_warp" >
      <div class="footer_cont"  style="display: flex;
      justify-content: center;align-items: center;">
        <div class="footer_cont_l">
          <div class="footer_cont_l_div" style="margin-left: -150px;">
            <div class="footer_cont_l_div_item">
              <ul class="footer_cont_l_ul" >
                <li
                  v-for="(item, index) in arrList"
                  :key="index"
                  @click="toGuid(item.url, item.id)"
                >
                  {{ item.title }}
                </li>

                <!-- <li>企业荣誉</li> -->
              </ul>
            </div>
            <div class="footer_cont_l_div_item">
              <p class="footer_cont_l_title">集团产业</p>
              <ul class="footer_cont_l_ul">
                <li
                  v-for="(item, index) in arrList2"
                  :key="index"
                  @click="toPages(item.url)"
                >
                  {{ item.title }}
                </li>
              </ul>
            </div>
            <!-- <div class="footer_cont_l_div_item">
              <p class="footer_cont_l_title">企业新闻</p>
              <ul class="footer_cont_l_ul">
                <li
                  v-for="(item, index) in arrList3"
                  :key="index"
                  @click="toNews(item.url)"
                >
                  {{ item.title }}
                </li>
              </ul>
            </div> -->
            <div class="footer_cont_l_div_item2">
              <p class="footer_cont_l_title">联系方式</p>
              <ul class="footer_cont_l_ul">
                <router-link to="lianxi"><li>联系我们</li></router-link>
              </ul>
            </div>
          </div>
        </div>
        <!-- top 中 -->
        <div class="footer_cont_c">
          <div>
            <el-image :src="require('../assets/img/bgimg2.jpg')" alt="" style="width: 170px;height: 50px;"/>
          </div>
          <div>
            <p class="address">
              公司总部地址：山东省烟台市芝罘区通世路16号<br />
              邮箱：lujuntao@126.com
            </p>
          </div>
        </div>
        <!-- top 右 -->
        <!-- <div class="footer_cont_r">
          <div>
            <div class="footer_cont_r_border">
              <img v-lazy="require('../assets/img/gongzhonghao.jpg')"  alt="" />
            </div>
            <p>关注微信公众号</p>
          </div>
        </div> -->
      </div>
      <!-- 下部 -->
      <div class="footer_warp_btm"  style="display: flex;
      justify-content: center;align-items: center;">
        <p style="display: flex;
      justify-content: center;align-items: center;">
          Copyright
          ©&ensp;&ensp;烟台小和网络科技有限公司&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;#鲁ICP备19018775号-1
        </p>
      </div>
    </div>

    <!-- --------- -->
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      // arrList: [
      //   {
      //     title: "关于企业",
      //     //url: "/jituangaikuo",
      //     //id: "about",
      //   },
      //   {
      //     title: "企业实力",
      //     // url: "/jituangaikuo",
      //     // id: "CultureInfo",
      //   },
      //   {
      //     title: "发展历程",
      //     // url: "/jituangaikuo",
      //     // id: "history",
      //   },
      // ],
      arrList2: [
        {
          title: "c5板块",
          url: "/keji",
        },
        {
          title: "c7板块",
          url: "/jinrong",
        },
        // {
        //   title: "电商板块",
        //   url: "/dianshang",
        // },
        // {
        //   title: "职教板块",
        //   url: "/zhijiao",
        // },
        // {
        //   title: "幼教板块",
        //   url: "/youjiao",
        // },
      ],
      arrList3: [
        {
          title: "企业要闻",
          url: "/xinwen",
        },
        // {
        //   title: "媒体报道",
        //   url: "/xinwen/MediaCoverage",
        // },
        // {
        //   title: "行业资讯",
        //   url: "/xinwen/TradeNews",
        // },
      ],
    };
  },

  mounted() {
    let _this = this;
    _this.$nextTick(function () {
      window.addEventListener("scroll", _this.handleScroll);
    });
  },

  methods: {
    // 锚点跳转
    toGuid(path, id) {
      localStorage.setItem("toId", id);
      this.$router.push(path);
      //查找存储的锚点id
      let Id = localStorage.getItem("toId");
      let toElement = document.getElementById(Id);
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView();
        localStorage.setItem("toId", "");
      }
    },

    toPages(url) {
      this.$router.push(url);
    },
    toNews(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style scoped>
@charset "utf-8";
/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.footer_warp {
  width: 100%;
  /* width: 1920px; */
  height: 388px;
  background: #17213d;
}
.footer_cont {
  width: 100%;
  /* width: 1200px; */
  margin: 0 auto;
  display: flex;
}
.footer_cont_l {
  font-size: 16px;
  font-weight: 500;
  color: #dddddd;
  line-height: 22px;
  padding-top: 77px;
}
.footer_cont_l_title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #dddddd;
  line-height: 22px;
  width: 64px;
}
.footer_cont_l_ul {
  margin-top: 36px;
}
.footer_cont_l_ul li {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-top: 10px;
  cursor: pointer;
}
.footer_cont_l_div {
  display: flex;
}
.footer_cont_l_div_item {
  margin-right: 72px;
}
.footer_cont_c {
  margin-top: 70px;
  margin-left: 80px;
}
.address {
  width: 467px;
  font-size: 16px;
  font-family: "PingFangSC-Regular", "PingFang SC";
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
  margin-top: 42px;
}
.footer_cont_r {
  margin-top: 79px;
  /* margin-left: 70px; */
}
.footer_cont_r p {
  text-align: center;
  font-size: 14px;
  font-family: "PingFangSC-Regular";
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  margin-top: 17px;
}
.footer_cont_r_border {
  width: 111px;
  height: 109px;
  border: 1px solid #979797;
}
.footer_cont_r_border img {
  width: 95px;
  height: 95px;
  margin: 7px 8px;
}
.footer_warp_btm {
  width: 1280px;
  margin: 0 auto;
  height: 65px;
  margin-top: 56px;

  border-top: 1px solid #444444;
}
.footer_warp_btm p {
  font-size: 12px;
  font-weight: 400;
  color: #a3a3a3;
  line-height: 48px;
}
</style>


